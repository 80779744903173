export default [
  {
    title: 'Groups',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Unions',
        route: 'group-union-list',
      },
      {
        title: 'Peoples',
        route: 'group-members-list',
      },
      {
        title: 'Transactions',
        route: 'group-transaction-list',
      },
      {
        title: 'Bank',
        route: 'group-bank-list',
      },
      {
        title: 'Events',
        route: 'group-event-list',
      },
    ],
  },
]
