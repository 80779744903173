<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <div
      v-if="$route.meta.showUnionSelection"
      class="align-items-center flex-grow-1 d-flex justify-content-center"
    >
      <select
        v-model="selectedUnion"
        class="form-control w-50"
        @change="unionChanged"
      >
        <option
          v-for="(item, index) of unions"
          :key="index"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userInfo.firstName }} {{ userInfo.surname }}
            </p>
            <span
              v-if="userInfo.roles"
              class="user-status"
            >{{
              userInfo.roles[0]
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userInfo.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { goToPath } from '@/auth/utils'
import { appUnionList } from '@/@core/services/groups'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      unions: [],
      selectedUnion: '',
    }
  },
  computed: {
    userInfo() {
      return window.ManMudra.getters.userInfo()
    },
  },
  mounted() {
    this.unionList()
    const data = window.ManMudra.getters.unionData()
    if (data) {
      this.$store.commit('groups/UPDATE_SELECTED_UNION', data)
      this.selectedUnion = data.id
      eventBus.$emit('union-changed', data)
    }
  },
  methods: {
    unionChanged() {
      // eslint-disable-next-line eqeqeq
      const item = this.unions.find(x => x.id == this.selectedUnion)
      if (item) {
        localStorage.setItem(jwtDefaultConfig.unionData, JSON.stringify(item))
        this.$store.commit('groups/UPDATE_SELECTED_UNION', item)
        eventBus.$emit('union-changed', item)
      }
    },
    logout() {
      window.ManMudra.actions.logout()
      goToPath(this, 'login')
    },
    unionList() {
      appUnionList().then(({ data }) => {
        if (data.succeeded) {
          this.unions = data.data
          this.$store.commit('groups/UPDATE_UNION_LIST', data.data)
          if (!this.$store.getters['groups/currentUnion']) {
            this.$store.commit('groups/UPDATE_SELECTED_UNION', data.data[0])
            this.selectedUnion = data.data[0].id
            eventBus.$emit('union-changed', data.data[0])
          } else {
            this.$store.commit('groups/UPDATE_SELECTED_UNION', window.ManMudra.getters.unionData())
          }
        }
      })
    },
  },
}
</script>
